/* 
body{
  padding: 50px;
} */

.box{
  box-sizing: border-box;
  padding-left: 15px;
}

.calender{
    width: 100%;
    box-shadow: 0px 4px 14.2px 0px rgba(169, 163, 163, 0.25);
    border-radius: 11px;
    font-family: "Jost", sans-serif;
    display: flex;
    overflow: hidden;
}

.calender .content{
  display: inline-block;
  width: 100%;
}

.calender .innner_content .day-details:first-child{
  margin-left: 15px;
}

.calender .innner_content{
  color: #727070;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 80%;
}

.calender .innner_content::-webkit-scrollbar {
  display: none;
}

.calender .rarrow{
  height: 20px;
  transform: rotate(180deg);
  cursor: pointer;
  display: inline-block;
  display: none;
}

.calender .day-details{
  display: block;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

/* .calender .day-details.active,
.calender .day-details:hover,
.calender .day-details:active,
.calender .day-details:focus{
  background: rgba(96, 163, 171, 0.3);
  color: #63a2aa;
}

.calender .day-details.active .dayname,
.calender .day-details:hover .dayname,
.calender .day-details:active .dayname,
.calender .day-details:focus .dayname{
  color: #63a2aa;
}

.calender .day-details.active .daydate,
.calender .day-details:hover .daydate,
.calender .day-details:active .daydate,
.calender .day-details:focus .daydate{
  color: #63a2aa;
} */

.calender .day-details.active .daydate,
.calender .day-details:hover .daydate,
.calender .day-details:active .daydate,
.calender .day-details:focus .daydate{
  background: #63a2aa;
  color: #FFF;
}

.calender .monthname{
  display: flex;
  padding: 0px 20px;
  text-align: center;
  background: rgba(96, 163, 171, 1);
  color: #FFF;
  font-weight: 400;
  line-height: 23.12px;
  border-radius: 10px 0px 0px 10px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.calender .daydate{
  border-radius: 10px;
  border: 1px solid rgba(96, 163, 171, 0.3);
  color: rgba(0, 0, 0, 1);
  margin: 10px 0px;
  padding: 10px 14px;
}

.dayname{
  color: rgba(109, 109, 109, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 23.12px;
}

.tbold{
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

label{
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: rgba(4, 4, 6, 1);
}

.slots{
  display: inline-block;
  width: 100%;
}

.slots .slot_content{
  color: #727070;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
}

.slots .slot_content::-webkit-scrollbar {
  display: none;
}

.slots .slot{
  border-radius: 10px;
  border: 1px solid rgba(96, 163, 171, 0.3);
  color: rgba(0, 0, 0, 1);
  margin-right: 20px;
  padding: 10px;
  display: block;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
  font-size: 16px;
}

.slots .slot.active,
.slots .slot:hover,
.slots .slot:active,
.slots .slot:focus{
  background: #63a2aa;
  color: #ffffff;
}

/* .slots .slot.active,
.slots .slot:hover,
.slots .slot:active,
.slots .slot:focus{
  background: rgba(96, 163, 171, 0.3);
  color: #63a2aa;
}

.slots .slot.active .dayname,
.slots .slot:hover .dayname,
.slots .slot:active .dayname,
.slots .slot:focus .dayname{
  color: #63a2aa;
}

.slots .slot.active .daydate,
.slots .slot:hover .daydate,
.slots .slot:active .daydate,
.slots .slot:focus .daydate{
  color: #63a2aa;
} */

.row{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
}

.row .col{
  width: 48%; 
}

.form-control{
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px !important;
    font-size: 14px;
    line-height: 20.23px;
    font-weight: 400;
    color: rgba(57, 91, 109, 0.44);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px !important;
    outline: none;
    border: 1px solid rgba(96, 163, 171, 0.3) !important;
}

.form-control:active,
.form-control:focus-visible,
.form-control:focus{
  border: 1px solid rgba(96, 163, 171, 1) !important;
}

::placeholder {
  color: rgba(57, 91, 109, 0.44);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: rgba(57, 91, 109, 0.44);
}

#root input{
  color: #395B6D !important;
}

.qcounter{
  display: flex;
  align-items: center;
}

.qcounter img{
  width: 24px;
  cursor: pointer;
}

.qcounter .qcounter-label{
  font-size: 20px;
  font-weight: 400;
  line-height: 20.23px;
  position: relative;
  top: -1px;
  color: rgba(57, 91, 109, 1);
  margin: 0px 16px;
}

.qcounter.qcounter-disabled .qcounter-label{
  color: rgba(212, 212, 212, 1);
}

.qcounter.qcounter-disabled img{
  filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%) contrast(85%);
}

.qcounter img.disabled{
  filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%) contrast(85%);
}

.cnter{
  font-weight: 400;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(57, 91, 109, 0.44);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px !important;
  padding: 7px !important;
}

.cnter.form-control:active,
.cnter.form-control:focus-visible,
.cnter.form-control:focus{
  border: 1px solid rgba(96, 163, 171, 0.3) !important;
}

.btnapp{
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
  transition-duration: .3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  background: rgba(96, 163, 171, 1); 
  color: #FFF;
  padding: 18px 60px 18px 60px;
  border-radius: 300px;
  outline: none !important;
}

.btnapp:hover,
.btnapp:focus,
.btnapp:active{
  transform: translateY(-8px) !important;
  background: rgba(96, 163, 171, 1) !important; 
  outline: none !important;
}

@media (max-width:420px){
  
  .row{
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
  }

  .row .col{
    width: 100%;
  }

  .mb{
    margin-bottom: 20px;
  }
}

.alert p{
  margin: 0px;
}

.alert{
    position: relative;
    margin-top: 1rem;
    padding:10px;
    border: 1px solid transparent;
    border-radius: 6px !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.alert-success{
  color: #63a2aa !important;
  background-color: rgba(96, 163, 171, 0.3) !important;
  border-color: rgba(96, 163, 171, 0.3) !important;
}

.calender .day-details:last-child{
  /* margin-right:100px */
}